<!--
 * @Descripttion: 
 * @version: 
 * @Author: Luochen
 * @Date: 2022-11-17 15:00:43
 * @LastEditors: Luochen
 * @LastEditTime: 2023-03-22 14:29:14
-->
<template>
  <div>
    <!-- <Header :url="require('@/assets/images/member.png')"/> -->
    <Header :banner="bannerList" />
    <Menu :menuList="menuList" @fun="typeClick" :type="'a'+type"></Menu>
    <div class="container">
      <div class="row" v-if="type==0">
        <div class="col col-lg-3 col-sm-12" v-for="(item,inx) in csxmList" :key="inx">
          <div class="proOne flexCenter cursor" @click="$util.goLink(item)">
            <img :src="item.img" alt="">
          </div>
        </div>
      </div>
      <div class="row" v-else style="margin-bottom: 20px;">
        <div class="col flexStart">
          <img :src="threeCsxmList.img" alt="" style="width: 152px">
        </div>
        <div class="col flexCenter">
          <div>
            <p class="defaultWord">{{ threeCsxmList.content}}</p>
            <!-- <p class="defaultWord">2007年起迄今,已持续举办八届全国插画双年展(CIB)和一系列插</p>
            <p class="defaultWord">画展览和活动。历经16余年考验的CIB插画双年展已得到国际插</p>
            <p class="defaultWord">画界的认可,成为国内插画艺术行业最具影响力、专业性、规模性、</p>
            <p class="defaultWord">高水准的双年大展。</p>
            <p class="defaultWord">CIB代表了中国插画艺术的水准。</p> -->
          </div>
        </div>
      </div>
      <!-- <div class="row" v-for="(item,inx) in csggList" :key="item.id">
        <div class="col" v-if="inx==0">
          <div class="proBanner cursor" @click="$util.goLink(item)">
            <img :src="item.img" alt="">
          </div>
        </div>
      </div> -->
     <swiper :imgs="csggList"></swiper>
     
      <div class="row"  v-if="type!=0">
        <div class="col">
          <p class="defaultWord mt20 pb10">
          <span aria-controls="student" aria-expanded="false" class="cursor"
                data-bs-target="#student" data-bs-toggle="collapse">
            专家/评审团
<!--            <img alt="" src="@/assets/images/bottom.png" style="width: 20px;margin-left: .5rem">-->
          </span>
          </p>
          <div class="line"></div>
          <div id="student" class="collapse show">
            <div class="row justify-content-start">
              <div class="col mb30 smBox" v-for="item in zjList" :key="item.id" >
                <div class="memHei flexCenter active">
                 <img :src="item.img" alt="" class="img-fluid">
                </div>
                <div class="mp1 mt10">{{item.title}}</div>
                <div class="mp2">{{item.f_title}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="type!=0" @click="zjLookMore">
        <div class="col dyMore flexCenter cursor mb40">
          查看更多
        </div>
      </div>
      <div class="row">
        <div class="col mb30 col-lg-3 col-sm-12" v-for="item in cswzList" :key="item.id" >
          <div class="proOne active flexCenter cursor" @click="$util.goLink(item)">
            <img :src="item.img" alt="" class="img-fluid">
            <div class="proTitle textLeft">{{item.title}}</div>
          </div>
        </div>
      </div>
      <div class="row" v-if="cswzList.length>0">
        <div class="col dyMore flexCenter cursor" @click="lookMore">
          查看更多
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import swiper from '@/components/swiperCom.vue';
export default {
  name: "about",
  components: {
    swiper
  },
  data(){
    return{
      menuList: [
        {
          id: 'a0',
          name: '全部',
        },
        {
          id: 'a1',
          name: 'CIB',
        }, {
          id: 'a2',
          name: 'GIIC',
        }, {
          id: 'a3',
          name: 'IIE',
        }, {
          id: 'a4',
          name: '会员展',
        }, {
          id: 'a5',
          name: '赛事',
        }
      ],
      csxmList:[],
      bannerList:[],
      threeCsxmList:[],
      csggList:[],
      page: 1,
      ad_page: 1,
      zj_page: 1,
      zj_last_page: 1,
      limit: 15,
      type: 0,
      last_page: '',
      cswzList: [],
      zjList: [],
      memberLogo:[
        {
          id:1,
          name:'泱泱文化',
          desc:'会长单位'
        },{
          id:2,
          name:'视觉中国',
          desc:'副会长单位'
        },{
          id:3,
          name:'华为主题',
          desc:'理事单位'
        },{
          id:4,
          name:'名创优品',
          desc:'理事单位'
        },{
          id:5,
          name:'万兴科技',
          desc:'理事单位'
        },{
          id:6,
          name:'巨有文化',
          desc:'理事单位'
        },{
          id:7,
          name:'麦小朵',
          desc:'会员单位'
        },{
          id:7,
          name:'麦小朵',
          desc:'会员单位'
        },{
          id:7,
          name:'麦小朵',
          desc:'会员单位'
        },{
          id:7,
          name:'麦小朵',
          desc:'会员单位'
        },{
          id:7,
          name:'麦小朵',
          desc:'会员单位'
        },{
          id:7,
          name:'麦小朵',
          desc:'会员单位'
        },{
          id:7,
          name:'麦小朵',
          desc:'会员单位'
        },{
          id:7,
          name:'麦小朵',
          desc:'会员单位'
        },{
          id:7,
          name:'麦小朵',
          desc:'会员单位'
        },{
          id:7,
          name:'麦小朵',
          desc:'会员单位'
        },{
          id:7,
          name:'麦小朵',
          desc:'会员单位'
        },{
          id:7,
          name:'麦小朵',
          desc:'会员单位'
        },
      ]
    }
  },
  methods:{
    //banner
    get_two_banner() {
      this.$get('/api/get_two_banner', { type: 4 }).then(res => {
        this.bannerList = res.data.data;
      }).catch(err => {

      })
    },
    //会员权益
    get_index_csxm() {
      this.$get('/api/get_index_csxm', {}).then(res => {
        this.csxmList = res.data;
      }).catch(err => {

      })
    },
    //常设项目中间广告位
    get_index_csgg() {
      this.$get('/api/get_index_csgg', { type: this.type }).then(res => {
        this.csggList = res.data;
      }).catch(err => {

      })
    },
    //常设项目三级页中间广告位
    get_index_three_ad() {
      this.$get('/api/get_index_three_ad', {type:this.type}).then(res => {
        this.csggList = res.data.data;
      }).catch(err => {

      })
    },
    //常设项目三级页顶部介绍
    get_index_three_csxm() {
      this.$get('/api/get_index_three_csxm', { page: this.ad_page, limit: this.limit, type: this.type }).then(res => {
        this.threeCsxmList = res.data.data[0];
      }).catch(err => {

      })
    },
    //专家评审
    get_index_three_zjps() {
      this.$get('/api/get_index_three_zjps', { page: this.zj_page, limit: this.limit, type: this.type }).then(res => {
        if (this.zj_page == 1) {
          this.zjList = res.data.data;
        } else {
          this.zjList = [...this.zjList, ...res.data.data];
        }
        this.zj_last_page = res.data.last_page;
      }).catch(err => {

      })
    },
    //获取文章列表
    get_index_cswz() {
      this.$get('/api/get_index_cswz', {page:this.page,limit:this.limit,type:this.type}).then(res => {
        if (this.page == 1) {
          this.cswzList = res.data.data;
        } else {
          this.cswzList = [...this.cswzList, ...res.data.data];
        }
        this.last_page = res.data.last_page;
      }).catch(err => {

      })
    },
    //分类点击
    typeClick(e) {
      console.log(e, e.substring(1, 2));
      this.type = e.substring(1, 2);
      this.page = 1;
      this.get_index_cswz();
      if(this.type==0){
        this.get_index_csgg();
      }else{
        this.get_index_three_ad();
        this.get_index_three_csxm();
        this.get_index_three_zjps();
      }
    },
    //点击查看更多
    lookMore() {
      if (this.page == this.last_page) {
        this.$message.warning('已经到底了')
      } else {
        this.page++;
        this.get_index_cswz()
      }
    },
    //专家评审点击查看更多
    zjLookMore() {
      if (this.zj_page == this.zj_last_page) {
        this.$message.warning('已经到底了')
      } else {
        this.zj_page++;
        this.get_index_three_zjps()
      }
    }
  },
  mounted(){
    this.type = this.$route.query.type ? this.$route.query.type:0
    if (this.type == 0) {
      this.get_index_csxm()
      this.get_index_csgg()
      this.get_index_cswz()
    } else {
      this.get_index_three_ad();
      this.get_index_three_csxm();
      this.get_index_three_zjps();
    }
    this.get_two_banner()
    
  }
}
</script>

<style scoped lang="scss">
.proOne{
  min-width: 260px;
  height: 300px;
  overflow: hidden;
  background: #fff078;
  overflow: hidden;
  margin-bottom: 2rem;
  img{
    width: 152px;
  }
  &.active{
    position: relative;
    background: transparent;
    .proTitle{
      width: 100%;
      background: rgba(29, 29, 29, .6);
      position: absolute;
      left: 0;
      bottom: -3px;
      color: #fff;
      padding: 10px;
    }
    img{
      width: 100%;
    }

  }
}
.proBanner{
  width: 100%;
  // height: 10rem;
  background: #afafaf;
  margin: 2rem 0;
  img{
    width: 100%;
  }
}
.smBox{
  max-width: 140px;
}
.memHei{
  width: 100%;
  height: 8.2rem;
  overflow: hidden;
  position: relative;
  min-width: 7rem;
  background: transparent;
  border: 1px solid #4d4d4d;
}
.mp1{
  font-size: 1rem;
  color: #afafaf;
}.mp2{
   font-size: .8rem;
   color: #afafaf;
 }
</style>
